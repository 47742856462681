import React, { cloneElement, Component } from 'react';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import * as R from 'ramda';

import { Checkbox } from '@common/components/form/inputs/checkbox';
import * as alert from '../../../../common/services/alert';
import Modal from '../../../../common/components/modal';
import { Button } from '../../../../common/components/button';
import Icon from '../../../../common/components/icon';
import AsyncList from '../../../../common/components/list/async';
import { updateUserRoles } from '../../actions';
import { ELevels } from '../../../../common/definitions';

const ADMIN_LEVEL = '';
const ACCESS_FULL = 'full';
const ACCESS_PARTIAL = 'partial';
const ACCESS_NONE = 'none';

const getDescription = (type) => {
  return `organisation:forms_role_item_type_${type}-description`;
};

const getPermissionPreviewData = (item, selectedPermissions) => {
  const areSelected = (permissions) => {
    return permissions?.every((permission) => selectedPermissions.includes(permission));
  };

  const context = `${item.level}-${item.name}`;
  const title = `organisation:forms_role_item_type_${context}`;

  if (Array.isArray(item.options)) {
    const adminPermissions = item.options.find((option) => option.sub_level === ADMIN_LEVEL);
    if (areSelected(adminPermissions?.permissions)) {
      return {
        access: ACCESS_FULL,
        title,
        descriptions: [getDescription(context)]
      };
    }
    const nonAdminDescriptions = item.options.filter((option) => {
      return option.sub_level !== ADMIN_LEVEL && areSelected(option.permissions);
    }).map((option) => {
      return getDescription(`${item.level}-${item.name}-${option.sub_level}`);
    });
    if (nonAdminDescriptions?.length > 0) {
      return {
        access: ACCESS_PARTIAL,
        title,
        descriptions: nonAdminDescriptions
      };
    }
  }
  return {
    access: areSelected(item.permissions) ? ACCESS_FULL : ACCESS_NONE,
    title,
    descriptions: [getDescription(context)]
  };
};

const getPreviewIcon = (accessType) => {
  switch (accessType) {
    case ACCESS_FULL:
      return <Icon type="check" className="RoleSelector__Group__Selected" />;
    case ACCESS_PARTIAL:
      return <Icon type="minus" className="RoleSelector__Group__PartialSelected" />;
    default:
      return <Icon type="close" />;
  }
};

export const getAdminRoleRowRenderer = (selectedPermissions) => {
  return ({ item }) => {
    const preview = getPermissionPreviewData(item, selectedPermissions);

    return (
      <div className="RoleSelector__Group" key={item.id}>
        { getPreviewIcon(preview.access) }
        <div>
          <h6>
            <Trans i18nKey={preview.title} />
          </h6>
          {
            preview.descriptions.map((description) => {
              return (
                <small key={description}>
                  <Trans i18nKey={description} components={[<b>a</b>]} />
                </small>
              );
            })
          }
        </div>
      </div>
    );
  };
};

class OrganisationAdminForm extends Component {
  static defaultProps = {
    updateAdmin: updateUserRoles,
  };

  constructor(props) {
    super();

    const { admin, roles } = props;

    this.state = {
      isVisible: false,
      selectedRoles: admin.roles.reduce((acc, role) => ({
        ...acc,
        [role.id]: roles.find((r) => r.id === role.id),
      }), {}),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static props;

  handleClose = () => {
    const { reset, onClose, admin } = this.props;
    this.setState({
      isVisible: false,
      selectedRoles: R.indexBy(R.prop('id'), admin.roles),
    });
    onClose?.();
    reset();
  };

  async handleSubmit(values, dispatch) {
    const {
      admin, updateAdmin, onSuccess, isSelect, network, roles
    } = this.props;
    if (isSelect) this.handleClose();

    const selected = R.values(this.state.selectedRoles);

    try {
      const payload = {
        add: R.pluck('id', R.filter((role) => !R.find(R.propEq('id', role.id), admin.roles), selected)),
        remove: R.pluck('id', R.filter((role) => !R.find(R.propEq('id', role.id), selected), admin.roles)),
      };

      await dispatch(updateAdmin(admin.id, payload, network?.id, roles));

      alert.success(this.props.t('organisation:forms_admin_success'));

      if (onSuccess) await onSuccess();
      this.handleClose();
    } catch (error) {
      alert.forStatus(error.status_code, {
        warning: this.props.t('organisation:forms_admin_warning'),
        error: this.props.t('organisation:forms_admin_error'),
      });
      throw error;
    }
  }

  handleToggleRole(role) {
    const { onChange } = this.props;
    const newSelectedRoles = this.state.selectedRoles[role.id]
      ? R.omit([role.id], this.state.selectedRoles)
      : {
        ...this.state.selectedRoles,
        [role.id]: role,
      };

    this.setState({
      selectedRoles: newSelectedRoles,
    });

    onChange?.(Object.values(newSelectedRoles));
  }

  render() {
    const { isVisible: internalIsVisible, selectedRoles } = this.state;
    const {
      network, roles, fetchGroups, onClose, handleSubmit, submitting, t, isSelect, show,
    } = this.props;

    const level = network ? ELevels.NETWORK : ELevels.ORGANISATION;
    const isVisible = typeof show === 'boolean' ? show : internalIsVisible;

    // Get all permissions from the selected roles and take the unique values
    const selectedPermissions = R.uniq(R.flatten(R.values(R.pluck('permissions', selectedRoles))));

    const renderRow = getAdminRoleRowRenderer(selectedPermissions);

    return (
      <Modal
        size="large"
        show={isVisible}
        onClose={this.handleClose}
        title={t(network ? 'network:forms_admin_title' : 'organisation:forms_admin_title')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(this.handleSubmit),
        }}
        onExited={onClose}
        content={(
          <div className="RoleSelector">
            <div className="RoleSelector__Roles">
              {[...roles].sort(({ is_global }) => (is_global ? -1 : 0)).map((role) => ( // eslint-disable-line camelcase
                <div key={role.id} className="RoleSelector__Roles__Item" onClick={() => this.handleToggleRole(role)}>
                  <Checkbox size="large" value={!!selectedRoles[role.id]} />
                  {role.name}
                  {role.is_global && <Icon type="star__filled" className="GlobalRole__Icon" />}
                </div>
              ))}
            </div>
            <div className="RoleSelector__Permissions">
              <AsyncList
                key={network?.id}
                data={{ onFetch: () => fetchGroups(level) }}
                renderRow={renderRow}
              />
            </div>
          </div>
        )}
        footer={(
          <Button
            type="primary"
            isLoading={submitting}
            buttonType={isSelect ? 'button' : 'submit'}
            onClick={isSelect ? this.handleClose : undefined}
          >
            <Trans i18nKey="organisation:forms_admins_edit" />
          </Button>
        )}
      >
        {this.props.children && (
          cloneElement(
            this.props.children,
            { onClick: () => this.setState({ isVisible: !isVisible }) },
          )
        )}
      </Modal>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'organisation-admin',
  initialValues: {}, // unused
})(OrganisationAdminForm));
