import React, { ReactNode, memo, PropsWithChildren } from 'react';
import BootstrapModal, { ModalProps } from 'react-bootstrap/Modal';
import { combineClassNames } from '../../utils/combineClassNames';

export type ModalWindowProps = PropsWithChildren<{
  list?: boolean;
  borderless?: boolean;
  className?: string;
  size?: 'small' | 'large';
  onHide?: ModalProps['onHide'];
  onShow?: ModalProps['onShow'];
  show?: boolean;
  trigger?: ReactNode | JSX.Element | JSX.Element[];
}>;

const ModalWindow = memo(({
  list,
  children,
  borderless,
  className,
  size,
  onShow,
  onHide,
  show = false,
  trigger,
  ...props
}: ModalWindowProps) => {

  const modalClassNames = combineClassNames(className, {
    'modal-list': !!list,
    'modal--borderless': !!borderless
  });

  // console.log("debug ModalWindow show", show);
  // console.log("debug ModalWindow trigger", trigger);

  return (
    <>
      {
        trigger && (
          <span role="button" onClick={onShow}>
            { trigger }
          </span>
        )
      }
      <BootstrapModal
        // @ts-expect-error
        size={size}
        className={modalClassNames}
        show={show}
        onHide={onHide}
        {...props}
      >
        {
          show && children
        }
      </BootstrapModal>
    </>
  );
});

// maybe create WrappedModalWindow which wraps its children directly with a
// Wrapper?

export default ModalWindow;
